<template>
    <div class="job-details">
        <router-link :to="{ name: 'GroupDetailDiscussion', params: { slug: data.slug } }" class="discuss-logo">
            <img :src="data.author.images !== '' && data.author.images !== null ? data.author.images : require('@/assets/images/avatars/avatar-1.jpg')">
        </router-link>
        <div class="job-description">
            <router-link :to="{ name: 'GroupDetailDiscussion', params: { slug: data.slug } }">
                <h3>{{ data.title }}</h3>
            </router-link>
            <p class="subtitle">Posted By: {{ data.author.fullname }} {{ formatDateFromNow(data.created_at) }}</p>
            <p v-if="data.topics && data.topics.length" class="subtitle">Topics: {{ data.topics.map(i => i.title).join(', ') }}</p>
            <div class="job-text" v-html="data.excerpt" v-linkified></div>
        </div>
        <div class="comments">
            <Icon icon="vs:comments" class="d-inline" /> {{ data.comments_count }}
        </div>
    </div>
</template>
<script>
    import { Icon } from '@iconify/vue2'

    export default {
        name: 'discussion_item',
        props: ['data'],
        components: {
            Icon
        }
    }
</script>
