<template>
    <div id="list_discuss">
        <div class="px-1">
            <div class="breadcrumbs">
                <a href="" @click.prevent="removeSelected()">All Topic</a> <Icon icon="dashicons:arrow-right-alt2" v-if="topic_selected !== ''" /> {{ topic_selected }}
                <a href="javascript:void(0)" @click.prevent="addTopic" style="float: right;" v-if="topic_selected === '' && detail.is_admin === 1">+ Add Topic</a>
                <a href="javascript:void(0)" @click.prevent="addThread" style="float: right;" v-if="topic_selected !== '' && detail.is_member === 1">+ Create Thread</a>
            </div>
            <transition name="slide-fade" mode="out-in">
                <div v-if="topic_selected === ''">
                    <div v-if="dataTopics && dataTopics.length > 0" class="row">
                        <div v-for="item in dataTopics" :key="item.id" class="col-md-4 mb-4">
                            <div class="card">
                                <a href="" @click.prevent="fetchThread(item.slug, item.title, item.id)">
                                    <img :src="item.images ? item.images : $placeholder2" alt="TOPICS" class="preview-topics">
                                </a>
                                <div class="topic-item-card">
                                    <a href="" @click.prevent="fetchThread(item.slug, item.title, item.id)">
                                        <p>{{ item.title }}</p>
                                    </a>
                                    <div class="group-button-topic" v-if="detail.is_admin === 1">
                                        <a href="" @click.prevent="editTopic(item)">
                                            <Icon icon="mdi:pencil-circle-outline" style="font-size: 30px;" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-center py-20">
                        <empty>
                            <p class="pt-2">No topic has beed added</p>
                        </empty>
                    </div>
                </div>
                <div v-if="topic_selected !== ''">
                    <div v-if="contentFilter.search">
                        <p class="m-0">Displaying search result for <strong>{{ contentFilter.search }}</strong></p>
                    </div>
                    <div class="job-list card" v-if="!loadingDiscussion">
                        <discussion-item v-for="item in dataDiscussion" :key="item.id" :data="item" />
                    </div>
                    <div class="job-list p-4 card" v-else>
                        <content-placeholders v-for="index in 3" :key="index" class="my-3">
                        <content-placeholders-heading :img="true" />
                        <content-placeholders-text :lines="3" />
                        </content-placeholders>
                    </div>
                    <div v-if="!loadingDiscussion && dataDiscussion.length < 1" class="text-center py-20">
                        <empty>
                            <p class="pt-2">No thread in this topic have beed created</p>
                        </empty>
                    </div>
                    <div v-if="!pagination.isLastPage && !loadingDiscussion" class="load-more-loading text-center py-4 mt-3">
                        <b-spinner v-if="pagination.loadMoreLoading" />
                        <a href="" v-else @click.prevent="loadMoreThread" class="card py-2 px-4 inline">Load More</a>
                    </div>
                </div>
            </transition>
        </div>
        <b-modal v-model="topics.form.show" :title="topics.form.title" ok-title="Submit" :hide-footer="true">
            <form @submit.prevent="">
                <b-form-group>
                    <div class="image-uploader mb-3">
                        <input type="file" ref="images" @change="onFileSelected($event, 'images')">
                        <span @click="$refs.images.click()"><Icon icon="bi:cloud-upload" /></span>
                        <img :src="images ? images : $placeholder2" alt="Images" class="w-80">
                    </div>
                </b-form-group>
                <b-form-group label="Title">
                    <b-form-input
                        v-model="topics.form.data.title"
                        placeholder="Add a title"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group label="Topic Privacy">
                    <select v-model="topics.form.data.privacy" class="form-control">
                        <option value="">Select Topic Privacy</option>
                        <option value="private">Private</option>
                        <option value="public">Public</option>
                    </select>
                </b-form-group>
                <b-form-group v-if="topics.form.data.privacy === 'private'" label="Select Member Who Can View Topic">
                    <v-select v-model="topics.form.data.members" multiple :reduce="member => member.id" :options="detail.members" label="fullname" />
                </b-form-group>
                <b-form-group align="right">
                    <div>
                        <b-button v-if="topics.form.data.id" variant="danger" class="mr-2" @click="deleteTopic">
                            Delete
                        </b-button>
                        <b-button variant="primary" type="submit" @click="submitTopic" size="sm">
                            Submit
                        </b-button>
                    </div>
                </b-form-group>
            </form>
        </b-modal>
        <form-discussion
            :group-id="$route.params.code"
            :topic-id="topic_selected_value"
            :loading="thread.form.loading"
            v-model="thread.form.data"
            @submit="submitThread"
        />
    </div>
</template>
<script>
    import { debounce } from 'vue-debounce'
    import { mapState, mapActions } from 'vuex'
    import DiscussionItem from '@/components/DiscussionItem'
    import { Icon } from '@iconify/vue2'
    import FormDiscussion from '@/components/FormDiscussion'
    import Empty from '@/components/Empty.vue'

    export default {
        name: 'discuss',
        components: {
            DiscussionItem,
            FormDiscussion,
            Icon,
            Empty
        },
        computed: {
            ...mapState('discussions', {
                dataDiscussion: state => state.data,
                loadingDiscussion: state => state.loading
            }),
            ...mapState('groups', {
                detail: state => state.selected.data,
                loading: state => state.selected.loading,
                contentFilter: state => state.selected.contentFilter
            }),
            ...mapState('topics', {
                dataTopics: state => state.data
            })
        },
        data () {
            return {
                topic_selected: '',
                topic_selected_value: '',
                topic_selected_slug: '',
                topics: {
                    form: {
                        title: '',
                        show: false,
                        loading: false,
                        data: {
                            id: '',
                            title: '',
                            privacy: '',
                            images: '',
                            members: []
                        }
                    }
                },
                images: '',
                thread: {
                    form: {
                        loading: false,
                        show: false,
                        inputTags: '',
                        data: {
                            title: '',
                            content: '',
                            topics: [],
                            disable_comments: false
                        }
                    }
                },
                pagination: {
                    currentPage: 1,
                    perPage: 5,
                    loadMoreLoading: false,
                    isLastPage: false
                }
            }
        },
        methods: {
            ...mapActions('groups', ['show']),
            ...mapActions('discussions', {
                _createThread: 'create',
                _updateThread: 'update',
                _fetchThread: 'fetch'
            }),
            ...mapActions('topics', {
                _createTopic: 'create',
                _updateTopic: 'update',
                _deleteTopic: 'delete',
                _fetchTopic: 'fetch'
            }),
            fetchThread (topic, title, id) {
                this.pagination.isLastPage = false
                this.topic_selected = title
                this.topic_selected_slug = topic
                this.topic_selected_value = id
                this._fetchThread({ group_id: this.$route.params.code, topic, limit: this.pagination.perPage })
            },
            searchData () {
                this.pagination.loadMoreLoading = true
                this._fetchThread({ group_id: this.$route.params.code, topic: this.topic_selected_slug, ...this.contentFilter })
                    .then(res => {
                        if (res.currentPage === res.lastPage) {
                            this.pagination.isLastPage = true
                        }
                    })
                    .finally(() => {
                        this.pagination.loadMoreLoading = false
                    })
            },
            loadMoreThread () {
                this.pagination.loadMoreLoading = true
                this._fetchThread({
                    topic: this.topic_selected_slug,
                    group_id: this.$route.params.code,
                    limit: this.pagination.perPage,
                    page: this.pagination.currentPage + 1,
                    append: true
                })
                    .then((res) => {
                        this.pagination.currentPage = this.pagination.currentPage + 1
                        if (res.currentPage === res.lastPage) {
                            this.pagination.isLastPage = true
                        }
                    })
                    .finally(() => {
                        this.pagination.loadMoreLoading = false
                    })
            },
            addThread () {
                this.$bvModal.show('modal-form-discussion')
                this.thread.form.show = true
            },
            submitThread () {
                this.thread.form.loading = true
                const { id, title, content, topics } = this.thread.form.data
                const groupId = this.detail.id
                const disableComments = this.thread.form.data.disable_comments

                this._createThread({ id, title, content, topics, disable_comments: disableComments, group_id: groupId }).then((res) => {
                    const topic = res.data.result.topics[0].slug
                    this._fetchThread({ group_id: this.$route.params.code, topic })
                    this.$bvModal.hide('modal-form-discussion')
                    this.thread.form.data = { id: '', title: '', content: '', topics: [], disable_comments: false }
                })
                    .finally(() => {
                        this.thread.form.loading = false
                    })
            },
            submitTopic () {
                this.topics.form.loading = true
                const { id, title, images, privacy, members } = this.topics.form.data
                const groupId = this.detail.id
                const promise = id ? this._updateTopic({ id, data: { title, images, privacy, members, group_id: groupId } }) : this._createTopic({ title, images, privacy, members, group_id: groupId })
                promise.then(() => {
                    this._fetchTopic({ group_id: this.$route.params.code })
                    this.topics.form.show = false
                    this.topics.form.data = { id: '', title: '', privacy: '', members: [], images: '' }
                })
                    .finally(() => {
                        this.topics.form.loading = false
                    })
            },
            fetchDetailGroup () {
                this.show(this.$route.params.code)
            },
            addTopic () {
                this.topics.form.title = 'Add Topic'
                this.topics.form.show = true
                this.topics.form.data.id = ''
                this.topics.form.data.title = ''
                this.topics.form.data.privacy = ''
                this.topics.form.data.members = []
                this.images = ''
            },
            editTopic (item) {
                this.topics.form.title = 'Edit Topic'
                this.topics.form.show = true
                this.topics.form.data = { ...item }
                this.images = item.images
            },
            deleteTopic () {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.topics.form.loading = true
                        const { id } = this.topics.form.data
                        this._deleteTopic(id)
                            .then(() => {
                                this._fetchTopic({ group_id: this.$route.params.code })
                                this.topics.form.show = false
                                this.topics.form.data = { id: '', title: '', privacy: '', members: [], images: '' }
                                this.$swal(
                                    'Deleted!',
                                    'Your topic has been deleted.',
                                    'success'
                                )
                            })
                            .finally(() => {
                                this.topics.form.loading = false
                            })
                    }
                })
            },
            removeSelected () {
                this.topic_selected = ''
                this.topic_selected_value = ''
            },
            onFileSelected (e, target) {
                this.topics.form.data[target] = e.target.files[0]
                this[target] = URL.createObjectURL(this.topics.form.data[target])
            }
        },
        watch: {
            'contentFilter.search': debounce(function () {
                this.searchData()
            }, 500),
            'contentFilter.order': debounce(function () {
                this.searchData()
            }, 500)
        },
        created () {
            // this.fetch({ group_id: this.$route.params.code })
        }
    }
</script>
